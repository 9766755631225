// ** Redux Imports
import { combineReducers } from '@reduxjs/toolkit'

// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import ShipMgmt from '@src/views/ShipMgmt/store'

const cityappReducer = {
  ShipMgmt
}

const appReducer = combineReducers({
  /* Vuexy */
  auth,
  navbar,
  layout,
  /* CityAPP */
  ...cityappReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'authentication/handleLogin' || action.type === 'authentication/handleLogout') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
